<template>
  <div class="p-5 font-size-30">
    <div class="font-weight-bold mb-3">Domain Blocked</div>
    <div>Domain ini sedang di blokir. Domain tidak memiliki akses ke aplikasi.</div>
    <div>Tersesat? <i class="fa fa-frown-o" aria-hidden="true"></i></div>
    <div class="font-weight-bold font-size-70 mb-1">Blocked —</div>
    <router-link to="/" class="btn btn-outline-primary width-100">Go Back</router-link>
  </div>
</template>
<script>
export default {
  name: 'CuiError404',
}
</script>
